import React, { Fragment } from 'react';

import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import ChannelListSummary from './channel_list_summary';
import AboutPageDrawer from '../../AboutDrawers/ChannelsPage/AboutPageDrawer';
import FilterChannelsModal from './filter_channels_modal';
import GeneralUpgradePlanModal from '../../my_settings/pricing_plans/general_upgrade_plan_modal';

import withRouter from '../../../util/with_router';
import { removeEmptyFromObject } from '../../util/helpers';
import { paramsToQueryString } from '../../../services/util/helpers';
import { getCountForChannels } from '../../../services/channel_api';

const stringEllipis = string => string && string.length > 27
  ? string.substring(0, 24) + '...'
  : string;

class ChannelList extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      creatingChannel: false,
      newChannelName: '',
      currentChannelId: '1',
      isAboutPageDrawerOpen: false,
      isChannelsLoading: true,
      isChannelsSummaryLoading: true,
    };
    this.createNewChannel = this.createNewChannel.bind(this);
    this.handleCreateNewPublicChannelSubmit = this.handleCreateNewPublicChannelSubmit.bind(this);
    this.handleChannelClick = this.handleChannelClick.bind(this);
    this.handleOnClickOpenAboutPageDrawer = this.handleOnClickOpenAboutPageDrawer.bind(this);
    this.onOpenAboutPagerDrawerClose = this.onOpenAboutPagerDrawerClose.bind(this);
    this.handleOnFilterChannels = this.handleOnFilterChannels.bind(this);
    this.handleOnOpenFilterChannelsModal = this.handleOnOpenFilterChannelsModal.bind(this);
    this.handleGeneralUpgradePlanModal = this.handleGeneralUpgradePlanModal.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    Modal.setAppElement(this.el);

    await this.props.fetchChannels();
    await this.props.fetchUserChannels();
    await this.props.fetchChannelsSummary();

    if (this._isMounted) {
      this.setState({
        isChannelsLoading: false,
        isChannelsSummaryLoading: false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  createNewChannel(e) {
    this.setState({
      creatingChannel: true
    });
  };

  update(field) {
    return (e) => this.setState({
      [field]: e.target.value
    });
  };

  handleCreateNewPublicChannelSubmit(e) {
    const channel = {
      name: this.state.newChannelName,
    }

    this.props.createChannel({ channel });
  }

  handleChannelClick(channelName) {
    const { channels, workspaceId } = this.props;
    const channel = channels.filter(channel => channel?.name === channelName)[0];
    this.setState({ currentChannelId: channel.id }, () => {
      document.cookie = `last_visited_channel = ${channel.id};`
      this.props.navigate(`/workspaces/${workspaceId}/requests/${channel.id}`);
    });
  }

  handleOnClickOpenAboutPageDrawer() {
    this.setState({ isAboutPageDrawerOpen: true });
  }

  onOpenAboutPagerDrawerClose() {
    this.setState({ isAboutPageDrawerOpen: false });
  }

  handleOnFilterChannels = ({ filterValuesSelected }) => {
    const { workspaceId } = this.props;

    const queryString = paramsToQueryString(
      removeEmptyFromObject(filterValuesSelected),
    );

    this.props.navigate(`/workspaces/${workspaceId}/requests/filter?${queryString || 'requests=all'}`)
  }

  handleOnOpenFilterChannelsModal = () => {
    const {
      channels,
      currentUser,
      isEducationOrganizationPersona,
      location,
      organizationPersona,
      workspaceId,
    } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <FilterChannelsModal
            channels={channels}
            currentUser={currentUser}
            isEducationOrganizationPersona={isEducationOrganizationPersona}
            organizationPersona={organizationPersona}
            location={location}
            onClick={this.handleOnFilterChannels}
            onClose={onClose}
            workspaceId={workspaceId}

          />
        );
      }
    });
  }

  handleGeneralUpgradePlanModal = async () => {
    const {
      currentLocale,
      currentUser,
      isFreeTrialAccount,
      openModal,
      workspaceId,
    } = this.props;

    if (!isFreeTrialAccount) {
      return openModal('new_public_channel', { currentLocale, currentUser, workspaceId });
    }

    const organizationId = currentUser?.organization_id;
    const response = await getCountForChannels({
      organizationId,
      workspaceId,
    });

    const count = response?.total_count;

    if (isFreeTrialAccount && count > 100) {
      return confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <GeneralUpgradePlanModal
              onClose={onClose}
              navigate={this.props.navigate}
            />
          );
        }
      });
    }

    return openModal('new_public_channel', { currentLocale, currentUser, workspaceId });
  }

  render() {
    const {
      currentUser,
      workspaceId,
      currentLocale,
      isCurrentUserAccountAdmin,
      isEducationOrganizationPersona,
      isFreeTrialAccount,
      channels,
      organization,
      workspaces,
    } = this.props;

    const { isChannelsLoading } = this.state;

    const workspace = workspaces.filter(workspace => workspace.id === workspaceId)[0];
    const workspaceName = workspace && workspace.name;
    const workspaceDescription = workspace && workspace.description;
    const workspaceInitial = workspaceName && workspaceName.charAt(0);

    const divStyle = {
      backgroundColor: 'rgb(76, 150, 137)',
    };

    const publicChannelsList = this.props.channels.map(((channel, i) => {
      const isSelectedChannel = channel?.id?.toString() === this.props.match.params.channelId;
      const listClassName = isSelectedChannel
        ? 'channel-list-item selected'
        : 'channel-list-item';

      return (
        <li
          key={i}
          className={listClassName}
          onClick={() => this.handleChannelClick(channel?.name)}
        >
          <div className="channel-name">
            <abbr title={channel?.name}>
              {stringEllipis(channel?.name)}
            </abbr>
          </div>
        </li>
      );
    }), this);

    const publicChannels = channels;

    return (
      <div className="channel-list-container" ref={ref => this.el = ref}>
        {isChannelsLoading ? (
          <div className="loading-spinner-container">
            <Spinner animation="border" role="status" variant="secondary" />
          </div>
        ) : (
          <Fragment>
            <div className="channel-list-header-container">
              <div className="workspace-icon">
                <div className="workspace-initial">{workspaceInitial}</div>
              </div>
              <div className="workspace-title">
                <div className="title">{workspaceName}</div>
                <div className="description">{workspaceDescription}</div>
              </div>
            </div>
            <div className="channels-container">
              <div className="summary-section">
                <div className="list-header summary">
                  <div className="header-container d-flex align-items-center">
                    <div className="header me-1">Filters</div>
                    <i
                      className="bi bi-filter channels-filter-icon"
                      onClick={() => this.handleOnOpenFilterChannelsModal()}
                    />
                  </div>
                  {!isEducationOrganizationPersona && (
                    <div
                      className="pro-tip-button"
                      onClick={() => this.handleOnClickOpenAboutPageDrawer()}
                    >
                      Pro Tip
                    </div>
                  )}
                </div>
                <ChannelListSummary
                  currentUser={currentUser}
                  fetchChannels={this.props.fetchChannels}
                  channelsSummary={this.props.channelsSummary}
                  isEducationOrganizationPersona={isEducationOrganizationPersona}
                  workspaceId={workspaceId}
                />
              </div>
              <div className="channel-list-section">
                <div className="list-header channels">
                  <div className="header-container d-flex align-items-center">
                    <div className="header me-1">
                      Requests
                    </div>
                    {/*
                      <i
                        className="bi bi-search channels-search-icon"
                        onClick={() => this.props.openModal('join_channel', { currentUser, workspaceId })}
                      />
                    */}
                  </div>
                  <i
                    onClick={() => isFreeTrialAccount
                      ? this.handleGeneralUpgradePlanModal()
                      : this.props.openModal('new_public_channel', { currentLocale, currentUser, workspaceId })
                    }
                    className="bi bi-plus channels-header-icon fa-plus"
                  />
                </div>
                <ul>
                  {publicChannelsList}
                </ul>
              </div>
            </div>
            <SlidingPane
              className='about-page-drawer channels-list'
              overlayClassName='sliding-pane-overlay'
              isOpen={this.state.isAboutPageDrawerOpen}
              title={'Pro Tip'}
              width='40%'
              subtitle=''
              onRequestClose={this.onOpenAboutPagerDrawerClose}>
                <AboutPageDrawer
                  currentUser={currentUser}
                  currentLocale={currentLocale}
                  isCurrentUserAccountAdmin={isCurrentUserAccountAdmin}
                />
            </SlidingPane>
          </Fragment>
        )}
      </div>
    );
  }
}

export default withRouter(ChannelList);
